import * as React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Picblock from '../components/picblock';
import * as styles from '../components/styles/index.module.css';

export default function SDIndex({ data }) {
  const picdata = data.allMarkdownRemark.nodes;

  return (
    <Layout>
      <Seo title="Home" />
      <div className={styles.impact}>
        Deliver on your company's data goverance ROI and focus on critical data.
      </div>
      <div className={styles.calltoaction}>
        Contact us to get direction on your data governance projects and determine the ROI on your critical data elements.
        <div>
          <Link to="/contact-us">
            <button className={styles.contact}>
              Contact Us
            </button>
          </Link>
        </div>
      </div>
      <div>
        { picdata.map((data) => (
          <Picblock data={data} key={data.id} />
        ))}
      </div>
    </Layout>
  );
}

export const query = graphql`
query imagepagedata {
    allMarkdownRemark(
      sort: {fields: frontmatter___sortorder, order: ASC}
      filter: {frontmatter: {page: {eq: "mainindex"}}}
    ) {
      nodes {
        id
        frontmatter {
          page
          pictitle
          sortorder
          title
          imageside
          imageurl {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
        }
        html
      }
    }
  }
  
`;

// TODO:  You can publish a test website with surge.sh.
